import VueRecaptcha from 'vue-recaptcha';

export default {
	name: "StructureYourWarrant",
	created () {
		// localStorage.clear()
		// this.$$.navbarAnimation()
		this.$nextTick(function () {
			this.GetUnderlyingList()
			this.ariseCombobox('#StructureYourcomboboxWarrants')
			this.ariseCombobox('#comboboxMoneyness')
			this.ariseCombobox('#comboboxMaturity')
			this.ariseCombobox('#comboboxType')
			if (this.$route.params.data !== undefined || localStorage.getItem('StructureYourName') !== null) {
				this.GetScreenerData(localStorage.getItem('StructureYourName'))
			}
		});
	},
	mounted () {
		this.$nextTick(function () {

		})
	},
	data () {
		return {
			underlyingLiRic: [],
			underlyingNmae: '',
			underlyingRic: '',
			underlyingBid: '',
			type: 'CALL',
			moneyness: 'At the money',
			maturity: '< 6 Month',
			comment: '',
			name: '',
			email: '',
			mobile: '',
			ModalText: '',
			Disabled: true,
			checkCaptch: false,
			checkedTo: false,
			recapctchacode: '',
			localStorageL: localStorage.getItem('lang')
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		onVerify: function (response) {
			this.recapctchacode = response;
			if (response == "") {
				this.checkCaptch = false;
			} else {
				this.checkCaptch = true;
				if (this.checkCaptch && this.checkedTo) {
					this.Disabled = false
				} else {
					this.Disabled = true
				}
			}
		},
		onExpired: function () {
			this.checkCaptch = false;
			this.Disabled = true;
		},
		// 获取 underlying ric
		GetUnderlyingList: function () {
			$.ajax({
				type: "GET",
				url: this.$$.mibb + "GetUnderlyingList",
				data: { token: "webkey", issuers: '' },
				dataType: "json",
				success: (data) => {
					if (data.status === "SUCCESS") {
						this.underlyingLiRic = data.underlying_name
						if (localStorage.getItem('StructureYourName') == null) {
							$('.comboboxWarrantsBox').find('input').val(`(${data.underlying_name[0].underlying_ticker}) (${data.underlying_name[0].underlying_code}) ${data.underlying_name[0].underlying_name}`)
							this.GetScreenerData(data.underlying_name[0].underlying_ticker)
						} else {
							this.GetScreenerData(data.underlying_name[0].underlying_ticker)
						}
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		},
		// 获取相应 underlying 的数据
		GetScreenerData: function (underlying) {
			let _this = this
			$.ajax({
				type: "POST",
				dataType: "json",
				url: this.$$.mibb + "GetScreenerData",
				data: {
					token: "webkey",
					underlying: underlying,
					type: "all",
					issuer: 'all',
					maturity: "all",
					moneyness: "all",
					effectiveGearing: "all",
					expiry: "all",
					sensitivity: 'all',
					sortBy: this.sortBy,
					sortOrder: this.sortOrder
				},
				success: res => {
					if (res.status == "SUCCESS") {
						this.underlyingNmae = res.data[0].underlying_name
						this.underlyingRic = res.data[0].underlying_ric

						if (res.data[0].underlying_ric !== '.HSI') {
							this.underlyingBid = `${res.data[0].underlying_curr} ${this.$$.HSINoDot(res.data[0].underlying_ric, res.data[0].underlying_bid, 0)}`
						} else {
							this.underlyingBid = ` ${this.$$.HSINoDot(res.data[0].underlying_ric, res.data[0].underlying_bid, 0)}`
						}

					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		StructureYourWarrant: function () {
			if ($('.form-check-input').is(":checked") && this.checkCaptch == true) {
				if (this.underlyingNmae && this.type && this.moneyness && this.comment && this.name && this.email && this.mobile && this.maturity) {
					this.Disabled = true;
					$.ajax({
						type: "GET",
						url: this.$$.mibbmail + "StructureYourWarrant",
						data: { underlying_nmll: this.underlyingNmae, type: this.type, moneyness: this.moneyness, comment: this.comment, name: this.name, email: this.email, mobile: this.mobile, maturity: this.maturity, code: this.recapctchacode },
						dataType: "json",
						success: (data) => {
							if (data.status === "SUCCESS") {
								this.ModalText = this.$t('mod.TehtM') + '.'
								// 模态框出现
								$('#StructureYourWarrantModal').modal('show')
								setTimeout(() => {
									this.$router.go(0);
								}, 1000);
							}
						},
						error: function (error) {
							console.log(error);
						}
					});
				} else {
					this.Disabled = false
					this.ModalText = this.$t('mod.Pfatf') + '.'
					// 模态框出现
					$('#StructureYourWarrantModal').modal('show')
				}
			} else {
				// 模态框出现
				$('#StructureYourWarrantModal').modal('show')
				this.ModalText = this.$t('mod.Patd') + '.'
			}
		},
		StructureYourWarrantInput: function (v, e) {
			this[v] = e.target.value
		},
		StructureYourWarrantChange: function (e) {
			this.checkedTo = e.target.checked
			if (this.checkedTo && this.checkCaptch) {
				this.Disabled = false
			} else {
				this.Disabled = true
			}
		},
		// 出现combobox
		ariseCombobox: function (id) {
			let _this = this
			this.$$.jqcombobox(id)
			$(id).combobox({
				select: function (event, ui) {
					switch (id) {
						case '#StructureYourcomboboxWarrants':
							if (this.value !== _this.$t('WSe.Others')) {
								localStorage.setItem("StructureYourName", this.value)
								_this.GetScreenerData(this.value)
							} else {
								_this.underlyingNmae = this.value
								_this.underlyingRic = ''
								_this.underlyingBid = this.value
							}

							break;
						case '#comboboxMoneyness':
							_this.moneyness = this.value
							break;
						case '#comboboxMaturity':
							_this.maturity = this.value
							break;

						case '#comboboxType':
							_this.type = this.value
							break;
					}
				}
			});
			$(id).parent().find('input').attr('placeholder', this.$t('WCn.SU'))
		},
	},
	components: { VueRecaptcha }
};